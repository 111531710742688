@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400&display=swap');

.containerDiv{
    display: flex;
}

.buttonsRow{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -4vw;
    width: 60%;
    flex-wrap: wrap;
    border-right: 1px solid lightslategray;
}

.buttonsRow button{
    border-bottom: 1px dashed #ccc;
    border-radius: 0;
    width: 100%;
    padding-bottom: 0;
    padding-top: 0;
}

.content{
    display: flex;
    justify-content: center;
    height: 80vh;
    width: 80vw;
    margin-right: -4vw;
}
.content > div{
    box-shadow: none;
}

.card{
    min-width: 60%;
}

.cardContent h2{
    text-align: center;
    word-wrap: break-word;
}

.iconButton{

}

.iconButton > span{
    display: flex;
    justify-content: flex-start;
}

.iconText{
    margin-left: 2px;
    font-size: small;
    font-family: 'Raleway', sans-serif;
    text-align: start;
    word-break: break-word;
}

.media{
    display: flex;
    justify-content: center;
    width: 100%;
}

.media > img{ 
    max-height: 80vh;
    max-width: 100%;
}

.downloadTextDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12vh;
    text-align: center;
}

.download{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1224px) {
    .containerDiv{
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        align-items: center;
    }
    .buttonsRow{
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        margin-left: 0;
        flex-wrap: nowrap;
        border-right: none;
    }
    .buttonsRow button{
        border-bottom: none;
        border-radius: 0;
        width: auto;
    }
    .iconButton{
        width: auto;
    }
    .content{
        height: 80%;
        padding: 5px;
    }
    .media{
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0px 0px;
    }
    .media > img{ 
        max-height: 70vh;
    }
    .media > video{
        width: 40vh;
        height: 100%;
    }
}
